<template>
	<div id="app">
		<NavBar v-if="loginLogo"></NavBar>
		<div ref="visual" :class="loginLogo ?  'visual' : ''">
			<keep-alive v-if="$route.meta.keepAlive">
				<router-view/>
			</keep-alive>
			<router-view v-else/>
		</div>
		<Footer v-if="loginLogo"></Footer>
	</div>
</template>

<script>
// navbar-c是最新的
import NavBar from '@/components/navbar/navbar-c.vue'
import Footer from '@/components/footer/footer.vue'

export default {
	components: {NavBar, Footer},
	data() {
		return {
			loginLogo: true,
		}
	},
	created() {
		window.addEventListener('resize', this.handleResize);
		this.handleResize(); // 初始化时也执行一次
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize);
	},
	// mounted() {
	//   if (this.$route.meta.isPosition) {
	//     this.$refs.visual.style.marginTop = '290px'
	//   }
	//   console.log(this.$route)
	// },
	mounted() {
		if (this._isMobile()) {
			window.open('https://zmxgw.nrunning.com/h5', '_self')
		}
	},
	methods: {
		handleResize() {
			const mobileWidth = 768; // 设置手机屏幕的宽度阈值
			if (window.innerWidth < mobileWidth) {
				// 如果宽度小于阈值，进行页面跳转或其他操作
				window.open('https://zmxgw.nrunning.com/h5', '_self')
			}
		},
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
			return flag
		}
	},
	watch: {
		'$store.state.listId': {
			handler(val) {
				sessionStorage.setItem('listId', val)
			},
			deep: true
		},
		$route(val) {
			if (val.path == '/home') {
				// this.$refs.visual.style.marginTop = '210px'
				this.$refs.visual.style.marginTop = '0'
				this.loginLogo = true
			} else if (val.path == '/achievementLogin' || val.path == '/login') {
				this.loginLogo = false
			} else {
				this.$refs.visual.style.marginTop = '0'
				this.loginLogo = true
			}
			// if (val.meta.isPosition) {
			//   // this.$refs.visual.style.marginTop = '290px'
			// } else {
			//   // this.$refs.visual.style.marginTop = '210px'
			// }
		}
	}
}
</script>

<style lang="less">
@import url('~@/assets/css/global.less');

/* 隐藏滚动条 */
::-webkit-scrollbar {
	display: none;
}

/* 允许滚动 */
.scrollable {
	-webkit-overflow-scrolling: touch; /* 移动端使用 */
	overflow-y: scroll; /* 对于需要滚动的元素 */
}
</style>
