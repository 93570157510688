import Vue from 'vue'
import VueRouter from 'vue-router'

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err)
}
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)
const routes = [
	// 首页
	{
		path: '/',
		redirect: '/home',
		component: () => import('@/views/home/index.vue'),
		meta: {
			isPosition: false,
			pathName: '首页',
			navBarIndex: 0
		},
		children: [
			{
				path: '/home',
				component: () => import('@/views/home/home.vue'),
				meta: {
					isPosition: false,
					pathName: '首页',
					keepAlive: true
				}
			},
			{
				path: '/search',
				name: 'search',
				component: () => import('@/views/home/search.vue'),
				meta: {
					isPosition: true,
					pathName: '搜索',
					keepAlive: false
				}
			},
			// 服务中心
			{
				path: '/service',
				redirect: '/information',
				component: () => import('@/views/home/service.vue'),
				meta: {
					isPosition: true,
					pathName: '服务中心',
					keepAlive: false
				},
				children: [
					{
						name: 'information',
						path: '/information',
						component: () => import('@/views/home/children/service/information.vue'),
						meta: {
							isPosition: true,
							pathName: '资料下载',
							asideNavMenuIndex: 0,
							keepAlive: false
						}
					},
					{
						name: 'opinion',
						path: '/opinion',
						component: () => import('@/views/home/children/service/opinion.vue'),
						meta: {
							isPosition: true,
							pathName: '意见反馈',
							asideNavMenuIndex: 1,
							keepAlive: true
						}
					},
				]
			},
			// 视频
			{
				path: '/video',
				redirect: '/vwrc',
				component: () => import('@/views/home/video.vue'),
				meta: {
					isPosition: true,
					pathName: '视频'
				},
				children: [
					{
						path: '/vwrc',
						name: 'vwrc',
						component: () => import('@/views/home/children/video/wrc.vue'),
						meta: {
							isPosition: true,
							pathName: '视频和图片锦集'
						}
					},
					{
						name: 'vdetail',
						path: '/vdetail',
						component: () => import('@/views/home/children/video/detail.vue'),
						meta: {
							isPosition: true,
							pathName: '视频详情'
						}
					}
				]
			},
		]
	},
	// 公共页面
	{
		path: '/public',
		redirect: '/plist',
		component: () => import('@/views/public/index.vue'),
		meta: {
			isPosition: false,
			pathName: '',
			navBarIndex: null
		},
		children: [
			{
				path: '/plist',
				name: 'plist',
				component: () => import('@/views/public/children/list.vue'),
				meta: {
					isPosition: true,
					keepAlive: false
				}
			},
			{
				path: '/pudetail',
				name: 'pudetail',
				component: () => import('@/views/public/children/detail.vue'),
				meta: {
					isPosition: true,
					keepAlive: false
				}
			},
			// 培训视频
			{
				path: '/trainingVideos',
				name: 'trainingVideos',
				component: () => import('@/views/public/children/trainingVideos.vue'),
				meta: {
					isPosition: true,
					keepAlive: false
				}
			},
			// 积分榜
			{
				path: '/integral',
				name: 'integral',
				component: () => import('@/views/public/children/integral.vue'),
				meta: {
					isPosition: true,
					pathName: '积分榜',
					asideNavMenuIndex: 3
				}
			},
			// 场地服务
			{
				path: '/venueServices',
				name: 'venueServices',
				component: () => import('@/views/public/children/venueServices.vue'),
				meta: {
					isPosition: true,
					pathName: '积分榜',
					asideNavMenuIndex: 3
				}
			}
		]
	},
	// 赛事活动报名模块
	{
		path: '/matchActive',
		redirect: '/match',
		component: () => import('@/views/matchActive/index.vue'),
		meta: {
			isPosition: false,
			pathName: '赛事活动',
			navBarIndex: null
		},
		children: [
			// 赛事列表
			{
				path: '/matchSignUp',
				name: 'matchSignUp',
				component: () => import('@/views/matchActive/children/matchSignUp.vue'),
				meta: {
					isPosition: true,
					keepAlive: false
				}
			},
			// 培训个列表
			{
				path: '/matchSignUpTrain',
				name: 'matchSignUpTrain',
				component: () => import('@/views/matchActive/children/matchSignUpTrain.vue'),
				meta: {
					isPosition: true,
					keepAlive: false
				}
			},
			// 赛事详情
			{
				path: '/matchDetail',
				name: 'matchDetail',
				component: () => import('@/views/matchActive/children/matchDetail.vue'),
				meta: {
					isPosition: true,
					keepAlive: false
				}
			},
			// 培训详情
			{
				path: '/trainDetail',
				name: 'trainDetail',
				component: () => import('@/views/matchActive/children/trainDetail.vue'),
				meta: {
					isPosition: true,
					keepAlive: false
				}
			},
			// 场地详情
			{
				path: '/fieldDetail',
				name: 'fieldDetail',
				component: () => import('@/views/matchActive/children/fieldDetail.vue'),
				meta: {
					isPosition: true,
					keepAlive: false
				}
			},

		]
	},
	{
		path: '/public2',
		redirect: '/plist2',
		component: () => import('@/views/public2/index.vue'),
		meta: {
			isPosition: false,
			pathName: '文本内容',
			navBarIndex: null
		},
		children: [
			{
				path: '/pudetail2',
				name: 'pudetail2',
				component: () => import('@/views/public2/children/detail.vue'),
				meta: {
					isPosition: true,
					keepAlive: false
				}
			},
		]
	},
	//登录
	{
		path: '/login',
		redirect: '/login',
		component: () => import('@/views/login/index.vue'),
		meta: {
			isPosition: false,
			pathName: '登录',
			navBarIndex: null
		},
		children: [
			{
				path: '/achievementLogin',
				name: 'achievementLogin',
				// component: () => import('@/views/login/children/achievementLogin.vue'),
				component: () => import('@/views/login/children/achievementLoginCopy.vue'),
				meta: {
					isPosition: true,
					keepAlive: false
				}
			},
		]
	},

]

const scrollBehavior = function (to, from, savedPosition) {
	if (savedPosition) {
		return savedPosition
	} else {
		return {x: 0, y: 0}
	}
}

const router = new VueRouter({
	routes,
	scrollBehavior,
	// mode: 'history',
	base: process.env.BASE_URL
})
export default router
