<template>
	<div class="page" ref="page" :class="navBarFixed ? 'navbar-bottom ' : 'navbar-top'">
		<div class="header">
			<img :src="$store.state.config.bz_logo" class="logo" @click="toHome"/>
			<div class="navbar" @mouseleave="mouseleave">
				<div class="content2 flex overFlew" @scroll="handleScroll">
					<div
						class="item"
						:class="{ active: index == 5 }"
						:style="index == currentIndex ? '' : 'background-image:none'"
						v-for="(item, index) in navbar"
						:key="index"
						@click="itemClick(index, item)"
						@mouseenter="mouseenter(index)"
						:ref="setRef"
					>
						<span :class="index == currentIndex&&currentIndex2==-1 ? 'tabs_item' : ''">{{ item.name }}</span>
						<div :class="index == currentIndex2 ? 'tabs_item' : ''"></div>
					</div>
				</div>
				<div class="content2">
					<div ref="select" class="select flex-direction-column" @mouseleave="mouseleave" v-show="currentIndex2!=-1">
						<div class="select-item_line" v-if="selectList.length>0">
							<div class="select-item_sanjiao"></div>
						</div>
						<div class="select-item" @click="selectClick(item, index)" v-for="(item, index) in selectList" :key="index"
								 :class="item.id == currentIDChoose ? 'choose' : ''">
							{{ item.name }}
						</div>
						<div v-if="navbar[mouseIndex]?.type==3">
							<div class="select-item_line">
								<div class="select-item_sanjiao"></div>
							</div>
							<div class="flex-direction-column img_styles">
								<img :src="navbar[mouseIndex].src" class="img_s">
								<div class="img_styles_text">
									请在小程序端购买
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
			<div class="search flex">
				<input v-model="key_words" type="text" placeholder="请输入关键词" @keyup.enter="search()"/>
				<img class="icon" src="../../assets/image/icon/13.png" @click="search"/>
			</div>
			<!--			<div class="user_login">-->
			<!--				<i class="el-icon-user icon_login"></i>-->
			<!--				用户登录-->
			<!--			</div>-->
		</div>
		<div class="banner"
				 v-if="banner && $route.path != '/home'&& $route.path != '/trainDetail'&& $route.path != '/fieldDetail'&& $route.path != '/matchDetail' ">
			<img :src="banner"/>
		</div>
		<div
			:style="{ marginTop: $route.path == '/trainDetail' || $route.path == '/fieldDetail'|| $route.path == '/matchDetail' ? '100px' : '0' }"></div>
		<div class="position" v-if="$route.meta?.isPosition" :style="{ marginTop: !banner ? '100px' : '0' }">
			<div class="content flex-space-between">
				<div class="position-left flex">
					<!--					<img src="@/assets/image/icon/06.png"/>-->
					<p>当前位置：</p>
					<div class="position-item flex">
						<span v-for="(item,index) in breadCrumbList" class="flex"
									:style="breadCrumbList.length==index+1 ? 'color:#212F82;font-weight: bold;' : ''"
									@click="routerClick(item,index)">
							{{ item.name }}
						<span v-if="index != breadCrumbList.length - 1" style="margin:0 10px"> ></span>
						</span>
					</div>
				</div>
				<!--				<div class="position-right flex" @click="backClick">-->
				<!--					<span>返回</span>-->
				<!--					<img src="@/assets/image/icon/07.png"/>-->
				<!--				</div>-->
			</div>
		</div>
	</div>
</template>

<script>
import {postColumn, getBanner, getBreadCrumb} from '@/service/api/index'
import {EventBus} from '@/utils/event-bus'

export default {
	data() {
		return {
			currentIndex: 0,
			currentIndex2: -1,
			navbar: [],
			selectList: [],
			mouseIndex: 0,
			banner: '',
			navBarFixed: false,
			key_words: this.$route.params.key_words ?? '',
			currentIDChoose: 0,
			refs: [],
			breadCrumbList: [],
			titleData: "",
			scrollLeftShow: 0
		}
	},

	mounted() {
		// 顶部导航
		this.postColumn()
	},

	methods: {
		toHome() {
			this.$router.replace('/home')
		},
		handleScroll(event) {
			this.scrollLeftShow = event.target.scrollLeft;
			this.logCenterPositions()
			this.mouseleave()
			this.$forceUpdate()
		},
		setRef(el) {
			if (el) {
				this.refs.push(el);
			}
		},
		getCenterPosition(element) {
			// console.log(window.screen.width)
			const rect = element.getBoundingClientRect();
			// console.log(rect)
			return {
				x: rect.width,
				// x: rect.left - 320 + rect.width / 2 - 80,
				y: rect.top + rect.height / 2,
			};
		},
		logCenterPositions() {
			this.refs.forEach((el, index) => {
				const center = this.getCenterPosition(el);
				// console.log(`Center of element: x=${center.x}, y=${center.y}`);
				this.navbar[index].left = center.x
			});
			//作为最初标识
			let navbarC = JSON.parse(JSON.stringify(this.navbar))
			//从第二项开始先将left距离计算出来
			this.navbar.forEach((item, index2) => {
				if (index2 > 0) {
					this.navbar[index2].left = this.navbar[index2 - 1].left + navbarC[index2].left
				}
			})
			//根据当前项的宽度/2,然后减掉底部固定宽度160/2。得出定位
			this.navbar.forEach((item, index23) => {
				item.left = item.left - navbarC[index23].left / 2 - 80 - this.scrollLeftShow
			})
		},
		getBanner(id) {
			getBanner({id}).then((res) => {
				this.banner = res.msg
			})
		},
		// 顶部导航
		postColumn() {
			postColumn().then((res) => {
				this.navbar = Object.freeze(res.msg)
				this.$nextTick(this.logCenterPositions);
			})
		},
		itemClick(index, item) {
			// this.currentIndex = index
			// this.getBanner(item.id)
			if (item.type == 0) {
				if (this.$route.path == "/home") {
					location.reload()
					return
				}
				this.currentIndex = index
				this.getBanner(1)
				this.$router.replace('/home')
				this.$refs.select.style.display = 'none'
				sessionStorage.setItem('navbarIndex', this.currentIndex)
				sessionStorage.setItem('currentIDChoose', -1)
				this.currentIDChoose = -1
			}
			// this.$refs.select.style.display = 'none'
			// sessionStorage.setItem('navbarIndex', this.currentIndex)

		},
		mouseenter(index) {
			this.currentIndex2 = index
			// console.log(index, this.selectList)
			this.mouseIndex = index
			this.$refs.select.style.opacity = 1
			this.$refs.select.style.display = 'block'
			this.selectList = this.navbar[index].child
			this.$refs.select.style.left = this.navbar[index].left + 'px'
		},
		mouseleave() {
			this.$refs.select.style.display = 'none'
			this.currentIndex2 = -1
		},
		selectClick(item, index) {
			this.banner = ''
			this.currentIndex = this.mouseIndex
			sessionStorage.setItem('params', JSON.stringify(item))
			this.$store.state.listId = item.id
			this.getBanner(item.column_id)
			this.$refs.select.style.display = 'none'
			sessionStorage.setItem('navbarIndex', this.currentIndex)
			sessionStorage.setItem('navbarId', item.id)
			sessionStorage.setItem('currentIDChoose', item.id)
			this.currentIDChoose = item.id
			if (item.type == 2) {
				return window.open(item.url, '_blank')
			} else if (item.status == 0) {
				this.$router.replace({
					path: '/plist',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 1) {
				//作为标识详情页
				this.$router.replace({
					path: '/pudetail',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 2) {
				this.$router.replace({
					name: 'matchSignUp',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 3) {
				this.$router.replace({
					name: 'integral',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 4) {
				this.$router.replace({
					name: 'matchSignUpTrain',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 5) {
				this.$router.replace({
					name: 'trainingVideos',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 6) {
				this.$router.replace({
					name: 'venueServices',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 7) {
				// token 没有就跳转
				this.$router.replace({
					name: 'achievementLogin',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
				// 有就跳转到另一个成绩页面
			}

			if (item.type != 2) {
				setTimeout(() => {
					EventBus.$emit('selectClick', {
						index,
						mouseIndex: this.mouseIndex
					})
				}, 200)
				setTimeout(() => {
					let obj = {
						name: this.navbar[this.mouseIndex].name,
						id: item.id
					}
					// item.name = this.navbar[this.mouseIndex].name
					EventBus.$emit('navbar', obj)
				}, 300)
			}
		},
		backClick() {
			this.$router.go(-1)
		},
		routerClick(item, index) {
			if (item.type == 0) return this.$router.replace('/home')
			sessionStorage.setItem('params', JSON.stringify(item))
			if (item.name == "站内搜索") {
				this.$router.replace({
					name: 'search',
					params: {
						info: JSON.stringify({
							id: 0,
							status: 0,
							type: 3
						}),
						key_words: this.key_words
					}
				})
				return
			}
			if (this.breadCrumbList.length == 2) return
			if (index == 0) {
				item = this.breadCrumbList[1]
			} else if (index == 1 && this.breadCrumbList[0].type == 0) {
				if (item.status == 0) {
					this.$router.replace({
						name: 'plist',
						params: {
							info: JSON.stringify({
								id: item.id,
								type: 1,
								status: item.status
							})
						}
					});
				}
				return
			}
			if (item.status == 0) {
				this.$router.replace({
					name: 'plist',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 1) {
				//作为标识详情页
				this.$router.replace({
					name: 'pudetail',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 2) {
				this.$router.replace({
					name: 'matchSignUp',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 3) {
				this.$router.replace({
					name: 'integral',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 4) {
				this.$router.replace({
					name: 'matchSignUpTrain',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 5) {
				this.$router.replace({
					name: 'trainingVideos',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 6) {
				this.$router.replace({
					name: 'venueServices',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (item.status == 7) {
				// token 没有就跳转
				this.$router.replace({
					name: 'achievementLogin',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			}

		},
		search() {
			if (!this.key_words) return this.$message('请输入关键词')
			this.getBanner(0)
			this.$store.state.key_words=this.key_words
			this.$router.replace({
				name: 'search',
				params: {
					info: JSON.stringify({
						id: 0,
						status: 0,
						type: 3
					}),
					key_words: this.key_words
				}
			})
		},
		getBreadCrumbD(id, status, type) {
			this.breadCrumbList = []
			getBreadCrumb({id, status, type}).then((res) => {
				console.log(res)
				if (res.msg.length > 0) {
					this.breadCrumbList = res.msg
				}
			})
		},
	},
	watch: {
		$route() {
			this.currentIndex = sessionStorage.getItem('navbarIndex')
			this.currentIDChoose = sessionStorage.getItem('currentIDChoose')
			let info = {}
			if (this.$route.params.info) {
				info = JSON.parse(this.$route.params.info)
			} else {
				info = JSON.parse(sessionStorage.getItem('params'))
			}
			if (info.type == 3) {
				this.getBreadCrumbD(info.id, info.status, 3)
				return
			}
			if (this.$route.path == '/pudetail2' || this.$route.path == '/matchDetail' || this.$route.path == '/trainDetail' || this.$route.path == '/fieldDetail') {
				this.getBreadCrumbD(info.id, info.status, 1)
			} else if (this.$route.path == '/vwrc') {
				this.getBreadCrumbD('0', '0', 2)
			} else {
				this.getBreadCrumbD(info.id, info.status, 0)
			}
		},
		'$store.state.listId': {
			handler(val) {
				this.currentIndex = sessionStorage.getItem('navbarIndex')
				this.currentIDChoose = sessionStorage.getItem('currentIDChoose')
				let info = {}
				if (this.$route.params.info) {
					info = JSON.parse(this.$route.params.info)
				} else {
					info = JSON.parse(sessionStorage.getItem('params'))
				}
				if (this.$route.path == '/pudetail' || this.$route.path == '/plist') {
					this.getBreadCrumbD(val ? val : 0, info.status ? info.status : 0, 0)
				}
			},
			deep: true,
			immediate: true
		},
	}
}
</script>

<style lang="less" scoped>
@keyframes topIndown {
	0% {
		-webkit-transform: translate3d(0, -20%, 0);
		transform: translate3d(0, -20%, 0);
		opacity: 0;
	}
	100% {
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}

@keyframes downIntop {
	0% {
		-webkit-transform: translate3d(0, 10%, 0);
		transform: translate3d(0, 10%, 0);
		// opacity: 0;
	}
	100% {
		-webkit-transform: none;
		transform: none;
		// opacity: 1;
	}
}

.user_login {
	display: flex;
	align-items: center;
	color: #FFFFFF;
	margin-left: 25px;
	font-size: 20px;
	white-space: nowrap;
	padding-right: 20px;

	.icon_login {
		margin-right: 5px;
	}
}

.search {
	background: rgba(255, 255, 255, 0.2);
	border-radius: 57px;
	width: 200px;
	position: relative;
	//margin-top: 20px;
	input {
		border: none;
		outline: none;
		margin: 14px 20px;
		background-color: transparent;
		color: #FFFFFF;
		width: 115px;
		font-size: 16px;

		&::placeholder {
			font-size: 16px;
			color: rgba(255, 255, 255, 0.5);
		}
	}

	.icon {
		position: absolute;
		right: 20px;
		width: 18px !important;
		height: 18px !important;
		color: #FFFFFF;
	}
}

.page {
	// position: fixed;
	// left: 0;
	// top: 0;
	// right: 0;
	width: 100%;
	position: relative;
	z-index: 99;
	overflow: hidden;
}

.navbar-bottom {
	// animation: topIndown 0.6s normal;
	position: sticky;
	top: 0;
	z-index: 99;
}

.navbar-top {
	// animation: downIntop 0.6s normal;
}

.header {
	width: 100%;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	top: 0;
	left: 0;
	right: 0;
	position: fixed;
	background: #212F82;

	.logo {
		width: 300px;
		height: 69.3px;
		//margin-left: 20px;
	}

	.navbar {
		height: 90px;
		min-width: 700px;
		position: relative;

		.overFlew {
			height: 100%;
			overflow-x: auto
		}

		.content2 {
			//height: 100%;
			width: 100%;
			position: relative;


			.active {
				display: block;
				//width: 204px !important;
			}

			.item {
				display: block;
				padding: 0 30px;
				white-space: nowrap;
				//width: 164px;
				//height: 60px;
				line-height: 60px;
				text-align: center;
				color: #fff;
				font-size: 17px;
				cursor: pointer;
				//background-image: url('../../assets/image/img/14.png');
				background-repeat: no-repeat;
				background-size: 100% 100%;
				position: relative;

				.tabs_item {
					border-bottom: 3px solid #FFFFFF;
				}

				&:hover {
					//background-image: url('../../assets/image/img/14.png') !important;
				}
			}


			.select {
				position: absolute;
				//left: 150px;
				z-index: 10;
				opacity: 0;
				transition: all 0.35s;
				white-space: nowrap;

				.choose {
					font-weight: bold;
					font-size: 16px;
					color: #B4375F;
				}

				.select-item_line {
					display: flex;
					align-items: center;
					justify-content: center;
					border-bottom: 5px solid #B4375F;

					.select-item_sanjiao {
						width: 0;
						height: 0;
						border-right: 15px solid transparent;
						border-left: 15px solid transparent;
						border-bottom: 15px solid #B4375F;
						//border-bottom: 15px solid transparent;
					}
				}

				.img_styles {
					background-color: #FFFFFF;
					width: 160px;
					height: 224px;
					align-items: center;
					border-right: 1px solid #f1f1f1;
					border-left: 1px solid #f1f1f1;
					border-bottom: 1px solid #f1f1f1;

					.img_s {
						width: 160px;
						height: 160px;
						margin-top: 10px;
					}

					.img_styles_text {
						margin-top: 10px;
						font-weight: 500;
						font-size: 15px;
						color: #333333;
						line-height: 18px;
					}
				}

				.select-item {
					border-right: 1px solid #f1f1f1;
					border-left: 1px solid #f1f1f1;
					text-align: center;
					padding: 0 20px;
					width: 120px;
					height: 44px;
					line-height: 44px;
					font-size: 16px;
					background: rgba(255, 255, 255);
					//margin-bottom: 2px;
					cursor: pointer;

					&:hover {
						color: #B4375F;
						font-weight: bold;
					}

					&:last-child {
						border-bottom: 1px solid #f1f1f1;
					}
				}
			}

		}

	}
}

.banner {
	width: 100%;
	height: 250px;
	margin-top: 90px;

	img {
		width: 100%;
		height: 250px;
		object-fit: cover;
	}
}

.position {
	width: 100%;
	//background: #fff;
	font-size: 16px;
	//margin-bottom: 20px;
	z-index: 0;
	margin-left: 22px;
	// top: 0px;
	// position: relative;
	// position: absolute;
	.position-left {
		height: 70px;

		img {
			width: 28px;
			height: 28px;
			margin-right: 10px;
		}

		p {
			height: 22px;
			line-height: 22px;
			color: #777777;
		}

		.position-item {
			cursor: pointer;

			span {
				height: 22px;
				line-height: 22px;
				color: #777777;
				//margin-right: 10px;
			}
		}
	}

	.position-right {
		cursor: pointer;

		span {
			color: #d6ad6e;
			height: 22px;
			font-size: 14px;
			line-height: 22px;
		}

		img {
			width: 13px;
			height: 10px;
			margin-left: 5px;
		}
	}
}

/* 隐藏滚动条 */
::-webkit-scrollbar {
	display: flex;
	width: 10px;
}

::-webkit-scrollbar { /*滚动条基本样式，高度*/
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-thumb { /*滚动条上层颜色*/
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	//background: rgba(0,0,0,0.2);
	background-color: #89898c;
}

::-webkit-scrollbar-track { /*滚动条底层颜色*/
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 0;
	background: rgba(0, 0, 0, 0.1);
}
</style>
